(function () {
    'use strict';

    var videoManager = {
        youtubeAPILoaded: false,
        youtubeAPIReady: null,

        init: function() {
            const videos = document.querySelectorAll('[data-video-id]');
            videos.forEach(video => {
                const videoType = video.getAttribute('data-type');
                if (videoType === 'youtube') this.initYoutube(video);
                else if (videoType === 'vimeo') this.initVimeo(video);
            });
        },

        initYoutube: async function(video) {
            if (!this.youtubeAPILoaded) {
                await this.loadYoutubeScript();
            }
            
            const videoId = video.getAttribute('data-video-id');            
            const playerElement = video.querySelector('[data-video-player]');
            if (playerElement && videoId) {
                const playerId = playerElement.getAttribute('id');
                
                const playButton = video.parentElement.querySelector('[data-video-thumb]');
                const playButtonParent = playButton.parentElement && playButton.parentElement.classList.contains('qld__video__thumbnail') ? playButton.parentElement : null; // podcast series
                const videoWrapper = video.closest('.qld__video_player__video'); //video_embed only

                if (playButton) {
                    playButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        if (playerId && window.YT) {
                            new YT.Player(playerId, {
                                height: "100%",
                                width: "100%",
                                videoId: videoId,
                                playerVars: { 'rel': 0, 'playsinline': 1, 'origin': window.location.origin },
                                events: {
                                    'onReady': (event) => {
                                        video.classList.remove('hidden');
                                        if(videoWrapper){
                                            videoWrapper.classList.remove('not-ready');
                                        }
                                        if (playButtonParent) {
                                            playButtonParent.style.display = "none";
                                            playButtonParent.setAttribute("hidden", "hidden");
                                        }
                                        event.target.playVideo();
                                    }
                                }
                            });
                        }
                    });
                }
            }
        },

        initVimeo: function(video) {
            const playButton = video.parentElement.querySelector('[data-video-thumb]');
            if (playButton) {
                playButton.addEventListener('click', (e) => {
                    e.preventDefault();
                    const videoWrapper = video.closest('.qld__video_player__video'); //video_embed only
                    const videoSource = video.getAttribute('data-source');
                    const videoHash = video.getAttribute('data-video-hash');
                    const queryParams = [
                        videoHash ? `h=${encodeURIComponent(videoHash)}` : '',
                        'autoplay=1'
                    ].filter(Boolean).join('&');
                    const videoUrl = `${videoSource}?${queryParams}`;
                    const videoIframe = video.querySelector('iframe');
                    videoIframe.onload = () => {
                        if(videoWrapper){
                            videoWrapper.classList.remove('not-ready');
                        }
                    };
                    videoIframe.src = videoUrl;
                    
                });
            }
        },

        loadYoutubeScript: function() {
            if (!this.youtubeAPIReady) {
                this.youtubeAPIReady = new Promise((resolve, reject) => {
                    if (document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
                        this.youtubeAPILoaded = true;
                        resolve();
                    } else {
                        const tag = document.createElement('script');
                        tag.src = "https://www.youtube.com/iframe_api";
                        document.head.appendChild(tag);

                        window.onYouTubeIframeAPIReady = () => {
                            this.youtubeAPILoaded = true;
                            resolve();
                        };

                        tag.onerror = reject;
                    }
                });
            }
            return this.youtubeAPIReady;
        }
    };

    $(document).ready(function() {
        QLD.videoManager = videoManager;
        QLD.videoManager.init();
    });

})();
